<template>
    <div>
        <van-tabs v-model:active="active" type="card" @click-tab="onClickTab" >
            <van-tab title="今日"></van-tab>
            <van-tab title="最後20筆"></van-tab>
        </van-tabs>
        <van-card
            v-for="(item) in dispatch" :key=item.id
            currency="$"
            :price="item.rental_cost"
            thumb="https://cars.chihlistudio.com/images/app/dispatch_history_image.png"
            
            >
            <template #title>
                <span style="font-size: 14px; font-weight: 600;">
                    {{ item.customer_name }}
                    <template v-if="item.customer_name.length <= 1">
                        {{ (item.customer_sex == '0' ? " 小姐/女士" : " 先生") }}
                    </template>
                </span>
            </template>
            <template #desc>
                <div>預約時間: {{ item.start_date }} {{ item.start_time }}</div>
                <div>連絡電話: <span style="color: blue;">{{ item.customer_mobile }}</span></div>
                <div>行駛路線: {{ item.route }}</div>
            </template>
            <template #num>
                <span style="font-size: 14px; font-weight: 600;">
                    <!-- <span style="margin-right: 1rem;">{{ item.start_time }}</span> -->
                    <van-button v-show="item.signature_file==null" color="#7232dd" style="margin-right: 3px;" @click="showSignature(item)">簽 名</van-button>
                    <van-button style="margin-right: 3px;" type="success" @click="editForm(item)">修 改</van-button>
                    <van-button type="primary" @click="showDispatch(item.image_path)">顯 示</van-button>
                </span>
            </template>
        </van-card>
        <van-popup round v-model:show="editFormPopUp" closeable position="bottom" :closeOnClickOverlay="false">
            <van-form @submit="onSubmit">
            <van-cell-group inset>
                <van-field
                    type="hidden"
                    v-model="dispatch.id"
                    name="id"
                />
                <van-field
                    v-model="dispatch.customer_name"
                    name="customer_name"
                    label="客戶名稱"
                    required
                    placeholder="客戶名稱"
                    :rules="[{ required: true, message: '請填寫客戶名稱' }]"
                />
                <van-field name="customer_sex" label="性別">
                    <template #input>
                        <van-radio-group v-model="dispatch.customer_sex" direction="horizontal">
                        <van-radio :name="0">女性</van-radio>
                        <van-radio :name="1">男性</van-radio>
                        <van-radio :name="2">無</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>
                <van-field
                    v-model="dispatch.customer_mobile"
                    type="tel"
                    name="customer_mobile"
                    label="聯絡電話"
                    required
                    placeholder="聯絡電話"
                    :rules="[{ required: true, message: '請填寫聯絡電話' }]"
                />
                <van-field
                    v-model="dispatch.customer_address"
                    name="customer_address"
                    label="客戶地址"
                    placeholder="請填寫客戶地址"
                />
                <van-field
                    v-model="dispatch.customer_id"
                    name="customer_id"
                    label="證件號碼"
                    placeholder="請填寫統一編號或證件號碼等"
                />
                <van-field
                    v-model="dispatch.people"
                    type="number"
                    name="people"
                    label="旅遊人數"
                    placeholder="請填寫旅遊人數"
                />
                <van-field
                    v-model="dispatch.start_date"
                    is-link
                    readonly
                    :columns-type="columnsType"
                    name="start_date"
                    label="租賃開始日期"
                    placeholder="請選擇租賃開始日期"
                    @click="showDatePicker = true"
                />
                <van-popup v-model:show="showDatePicker" position="bottom">
                    <van-date-picker :model-value="pickerStartDateValue" class="custom-date-picker" @confirm="onDateConfirm" @cancel="showDatePicker = false" :min-date="minDate" :max-date="maxDate" confirm-button-text="確認"/>
                </van-popup>
                <van-field
                    v-model="dispatch.start_time"
                    is-link
                    readonly
                    name="start_time"
                    label="開始時間"
                    placeholder="請選擇租賃開始時間"
                    @click="showTimePicker = true"
                />
                <van-popup v-model:show="showTimePicker" position="bottom">
                    <van-time-picker :model-value="pickerStartTimeValue" class="custom-date-picker" @confirm="onTimeConfirm" @cancel="showTimePicker = false" confirm-button-text="確認" :filter="filter" />
                </van-popup>
                <van-field
                    v-model="dispatch.end_date"
                    is-link
                    readonly
                    :columns-type="columnsType"
                    name="end_date"
                    label="租賃結束日期"
                    placeholder="請選擇租賃結束日期"
                    @click="showDateEndPicker = true"
                />
                <van-popup v-model:show="showDateEndPicker" position="bottom">
                    <van-date-picker :model-value="pickerEndDateValue" class="custom-date-picker" @confirm="onDateEndConfirm" @cancel="showDateEndPicker = false"  :min-date="minDate" :max-date="maxDate" confirm-button-text="確認" />
                </van-popup>
                <van-field
                    v-model="dispatch.end_time"
                    is-link
                    readonly
                    name="end_time"
                    label="結束時間"
                    placeholder="請選擇租賃結束時間"
                    @click="showTimeEndPicker = true"
                />
                <van-popup v-model:show="showTimeEndPicker" position="bottom">
                    <van-time-picker :model-value="pickerEndTimeValue" class="custom-date-picker" @confirm="onTimeEndConfirm" @cancel="showTimeEndPicker = false" confirm-button-text="確認" :filter="filter" />
                </van-popup>
                <van-field
                    v-model="dispatch.route"
                    name="route"
                    label="行駛路線"
                    placeholder="請填寫行駛路線"
                />
                <van-field
                    v-model="dispatch.rental_cost"
                    name="rental_cost"
                    type="number"
                    label="租車費用"
                    placeholder="租車費用"
                    :rules="[{ message: '請填寫租車費用' }]"
                />
                <van-field
                    v-model="dispatch.odometer"
                    name="odometer"
                    type="number"
                    label="里程數"
                    placeholder="請填寫車輛里程數"
                />
                <van-field
                    v-model="dispatch.flight_no"
                    name="flight_no"
                    label="航班編號"
                    placeholder="請填寫航班編號"
                />
            </van-cell-group>
            <div style="margin: 16px;">
                <van-button round block type="primary" native-type="submit">
                更 新
                </van-button>
            </div>
            </van-form>
        </van-popup>
        <van-popup v-model:show="showDispatchPopUp" style="padding-top: 5px; text-align: center;">
            <img height="100%" width="100%" :src="image_path" alt="">
        </van-popup>
        <van-popup v-model:show="showSignaturePopUp" closeable position="bottom" :closeOnClickOverlay="false">
            <van-cell-group inset>
                <van-field
                    v-model="select_customer_name"
                    name="select_customer_name"
                    label="客戶名稱"
                    disabled
                    placeholder="請填寫客戶名稱"
                />
                <van-field
                    v-model="select_start_date"
                    name="select_start_date"
                    label="日期"
                    disabled
                    placeholder="請填寫日期"
                />
                <van-field
                    v-model="select_rental_cost"
                    name="select_rental_cost"
                    disabled
                    label="金額"
                    placeholder="請填寫金額"
                />
            </van-cell-group>
            <div style="background-color: #87deb9">
                <van-signature @submit="onSignature" @clear="onClear" confirmButtonText="確 認" clearButtonText="清除" />
            </div>
        </van-popup>
        <van-empty v-show="dispatch.length==0" description="目前無派車簽單!" />
    </div>
</template>
<script setup>
import{ ref, reactive, onMounted } from 'vue';
import axios from 'axios'
import liff from '@line/liff';
import { showToast, showLoadingToast, closeToast } from 'vant';

let dispatch = ref([
    {'id': '',
    'dispatch_id': '',
    'customer_name': '',
    'customer_sex': 0,
    'customer_mobile': '',
    'customer_address': '',
    'customer_id': '',
    'people': '',
    'start_date': '',
    'start_time': '',
    'end_date': '',
    'end_time': '',
    'route': '',
    'rental_cost': '',
    'odometer': '',
    'line_id': '',
    'nickname': '',
    'flight_no': ''}
]);
let image_path = ref();
const editFormPopUp = ref(false);
const active = ref(0);
const today = new Date();
const signature_file = ref(null);
const showDispatchPopUp = ref(false);
// const showDispatchPopUp2 = ref(false);
// const showNotePopUp = ref(false);
const select_dispatch_id = ref();
const pickerStartDateValue = ref([])
const pickerStartTimeValue = ref([])
const pickerEndDateValue = ref([])
const pickerEndTimeValue = ref([])
let minDate = new Date(formatDate(today))
let maxDate = new Date(2028, 5, 1)
const showDatePicker = ref(false);
const showTimePicker = ref(false);
const showDateEndPicker = ref(false);
const showTimeEndPicker = ref(false);
const columnsType = ['year', 'month', 'day'];
const showSignaturePopUp = ref(false);
// const select_dispatch_id = ref();
const select_customer_name = ref();
const select_start_date = ref();
const select_rental_cost = ref();

// let update = reactive({
//         'id': '',
//         'dispatch_id': '',
//         'customer_name': '',
//         'customer_sex': '0',
//         'customer_mobile': '',
//         'customer_address': '',
//         'people': '',
//         'start_date': '',
//         'start_time': '',
//         'end_date': '',
//         'end_time': '',
//         'route': '',
//         'rental_cost': '',
//         'odometer': '',
//         'line_id': '',
//         'nickname': '',
//         'flight_no': ''
//     });
let person = reactive({
    'line_id': '',
    'nickname': ''
});
const onClickTab = async (data) => {
    console.log('onClickTab ', active);
    console.log('data ', data);
    let search = 'today';
    showLoadingToast({
        type: "loading",
        message: "載入中...",
        forbidClick: true,
        overlay: true,
        duration: 0,
        loadingType: "spinner"
    });
    if(active.value == 0) {
        search = 'today'
    }else if(active.value == 1) {
        search = 'all'
    }else{
        search = 'today'
    }
    const payload = reactive({
        "line_id": person.line_id,
        "search": search
    })
    const response = await axios.post('https://cars.chihlistudio.com/api/historyv2', payload)
    // const response = await axios.post('https://2170-123-252-20-145.ngrok-free.app/api/historyv2', payload)
    console.log('response ', response.data.data);
    if(response.status !== 200)
    {
        showToast('載入失敗!');
        console.log('response error ', response);
    }else{
        dispatch.value = response.data.data
        console.log('dispatch ', dispatch.value);
    }
    closeToast();
}
// const onClear = () => {
// }
const showSignature = (item) => {
    select_dispatch_id.value = item.dispatch_id
    console.log('showSignature dispatch_id ', item);
    select_customer_name.value = item.customer_name
    select_rental_cost.value = item.rental_cost
    select_start_date.value = item.start_time
    showSignaturePopUp.value = true
}
const onClear = () => {
}
const onSignature = (data) => {
    // console.log('onSignature ', data);
    signature_file.value = data.image
    // console.log('signature_file ', signature_file.value);
    if(!signature_file.value) {
        showToast('請先簽名!');
        return false;
    }
    showLoadingToast({
        type: "loading",
        message: "載入中...",
        forbidClick: true,
        overlay: true,
        duration: 0,
        loadingType: "spinner"
    });
    axios.post('https://cars.chihlistudio.com/api/uploadsignature', {
        'dispatch_id': select_dispatch_id.value,
        'line_id': person.line_id,
        'imgBase64': signature_file.value
    }).then((rs) => {
        console.log('rs ', rs);
        if(rs.data.success){
            showToast('更新成功!');
            window.location.reload()
        }
    })
    closeToast();
    showSignaturePopUp.value = false
}
const onSubmit = (data) => {
    console.log('data ', data);

    showLoadingToast({
        type: "loading",
        message: "載入中...",
        forbidClick: true,
        overlay: true,
        duration: 0,
        loadingType: "spinner"
    });
    const payload = {
        "id": data.id,
        "line_id": person.line_id,
        "customer_name": data.customer_name,
        "customer_sex": data.customer_sex,
        "customer_mobile": data.customer_mobile,
        "customer_address": data.customer_address,
        "customer_id": data.customer_id,
        "people": data.people,
        "start_time": data.start_date+' '+data.start_time,
        "end_time": data.end_date+' '+data.end_time,
        "route": data.route,
        "rental_cost": data.rental_cost,
        "odometer": data.odometer,
        "nickname": person.nickname,
        "flight_no": data.flight_no
    }
    // console.log('data ', payload);
    // axios.post('https://2170-123-252-20-145.ngrok-free.app/api/editdispatch', {
    axios.post('https://cars.chihlistudio.com/api/editdispatch', payload).then((rs) => {
        console.log('rs ', rs);
        if(rs.data.success){
            showToast('更新成功!');
            window.location.reload()
        }
    })
    closeToast();
    showSignaturePopUp.value = false
}
    const editForm = (item) => {
        console.log('showSignature dispatch_id ', item);
        const [sdate, stime] = item.start_time.split(' ');
        
        const [syear, smonth, sday] = sdate.split('-').map(Number)
        const [shours, sminutes] = stime.split(':').map(Number)
        
        dispatch.value.id = item.id
        dispatch.value.dispatch_id = item.dispatch_id
        dispatch.value.customer_name = item.customer_name
        dispatch.value.customer_sex = item.customer_sex
        dispatch.value.customer_mobile = item.customer_mobile
        dispatch.value.customer_address = item.customer_address
        dispatch.value.customer_id = item.customer_id
        dispatch.value.people = item.people
        dispatch.value.route = item.route
        dispatch.value.rental_cost = item.rental_cost
        dispatch.value.odometer = item.odometer
        dispatch.value.flight_no = item.flight_no
        dispatch.value.start_date = sdate
        dispatch.value.start_time = stime
        if(item.end_time == null){
            dispatch.value.end_date = ''
            dispatch.value.end_time = ''
            pickerEndDateValue.value = ['', '', '']
            pickerEndTimeValue.value = ['', '']
        }else{
            const [edate, etime] = item.end_time.split(' ');
            const [eyear, emonth, eday] = edate.split('-').map(Number)
            const [ehours, eminutes] = etime.split(':').map(Number)
            dispatch.value.end_date = edate
            dispatch.value.end_time = etime
            pickerEndDateValue.value = [eyear, emonth, eday]
            pickerEndTimeValue.value = [ehours, eminutes]
        }
        pickerStartDateValue.value = [syear, smonth, sday]
        pickerStartTimeValue.value = [shours, sminutes]

        editFormPopUp.value = true
    }
    const showDispatch = (data) => {
        // console.log('dispatch_id ', data);
        // image_path.value = 'https://e0de0a6e28da.ngrok.app/' + data
        image_path.value = 'https://cars.chihlistudio.com/' + data
        // console.log('image_path ', image_path);
        showDispatchPopUp.value = true
    }
    const filter = (type, options) => {
        if (type === 'minute') {
            return options.filter((option) => Number(option.value) % 10 === 0);
        }
        return options;
    };

    const onDateConfirm = ({ selectedValues }) => {
        // console.log(selectedValues)
        dispatch.value.start_date = selectedValues.join('-');
        dispatch.value.end_date = selectedValues.join('-');
        showDatePicker.value = false;
    };
    const onTimeConfirm = ({ selectedValues }) => {
        dispatch.value.start_time = selectedValues.join(':');
        dispatch.value.end_time = selectedValues.join(':');
        showTimePicker.value = false;
    };
    const onDateEndConfirm = ({ selectedValues }) => {
        dispatch.value.end_date = selectedValues.join('-');
        showDateEndPicker.value = false;
    };
    const onTimeEndConfirm = ({ selectedValues }) => {
        dispatch.value.end_time = selectedValues.join(':');
        showTimeEndPicker.value = false;
    };
    function formatDate (date) {
            const year = String(date.getFullYear());
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        }
// function formatDateToArray (date) {
//     const year = String(date.getFullYear());
//     const month = String(date.getMonth() + 1).padStart(2, '0');
//     const day = String(date.getDate()).padStart(2, '0');
//     return [year, month, day];
// }
onMounted( async () => {
    liff.init({
      liffId: '2005566839-QorBoEre', // Use own liffId
      // withLoginOnExternalBrowser: true,
    }).then(function() {
        if (!liff.isLoggedIn()) {
            liff.login({ 
                // 登入後要轉址到哪個網站
                redirectUri:"https://dev.chihlistudio.com/historyv3" 
            });
        }else{
            liff.getProfile()
            .then(profile => {
                console.log('profile ', profile);
                const name = profile.displayName
                person.nickname = name
                person.line_id = profile.userId
                showLoadingToast({
                    type: "loading",
                    message: "載入中...",
                    forbidClick: true,
                    overlay: true,
                    duration: 0,
                    loadingType: "spinner"
                });
                const payload = reactive({
                    "line_id": person.line_id,
                    'search': 'today'
                })
                axios.post('https://cars.chihlistudio.com/api/historyv2', payload).then((rs) => {
                // axios.post('https://2170-123-252-20-145.ngrok-free.app/api/historyv2', payload).then((rs) => {
                    console.log('rs ', rs.data.data);
                    dispatch.value = rs.data.data
                    closeToast();
                })
            })
            .catch((err) => {
                console.log('error', err);
            });
        }
    
    }).catch(function(error) {
        console.log(error);
    });
    
})
</script>
<style scoped>
.custom-date-picker { --van-picker-background: #eceba5; }
</style>